import { FetchStatus } from '@/models/enum/app';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { WithdrawalTextContainer, WithdrawalWrapper } from '@/components/organisms/Buyer/Account/StyledComponents';
import { Breakpoints } from '@/constants/constants';
import { PrimaryButton, SecondaryButton } from '@/components/atoms/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as member from '@/state/modules/member';
import { navigate } from 'gatsby';
import { Routings } from '@/common/routings';
import Auth from '@aws-amplify/auth';
import { SpBr } from '@/components/atoms/CommonStyles';
import InfoIcon from '@/components/atoms/Icons/InfoIcon';
import theme from '@/styles/theme';

const FunctionListGroup = styled.ul`
  display: inline-block;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  color: ${theme.colors.text};
  letter-spacing: 0;
  margin-top: 20px;
  margin-bottom: 41px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    font-size: 14px;
  }
`;

const ListItem = styled.li`
  text-align: left;
`;

const WarningTextWrapper = styled.div`
  font-size: 12px;
  line-height: 22px;
  margin-top: 21px;
  display: inline-block;
  vertical-align: middle;
`;

const WarningText = styled.span`
  margin-left: 12px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 42px;

  // SPの場合、ボタンの順番を逆にする
  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column-reverse;
  }
`;

const ButtonWrapper = styled.div`
  // Breakpoints.sp と一致する場合に表示がおかしくなるため、+1px している
  @media screen and (min-width: ${Breakpoints.sp + 1}px) {
    width: calc(100% / 3);
    text-align: center;
  }
`;

const CancelButton = styled(SecondaryButton)`
  width: 190px;

  @media screen and (max-width: ${Breakpoints.pc}px) {
    margin: 0 auto;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-left: 0;
    width: 100%;
  }
`;

const WithdrawalButton = styled(PrimaryButton)`
  width: 300px;

  @media screen and (max-width: ${Breakpoints.pc}px) {
    margin: 0 auto;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    // SPの場合、マージンを開ける
    margin-bottom: 20px;
    margin-left: 0;
    width: 100%;
  }
`;

const Before = () => {
  const dispatch = useDispatch();
  const deleteMemberStatus = useSelector(member.selectors.deleteMemberStatus);

  useEffect(() => {
    if (deleteMemberStatus.status === FetchStatus.DONE) {
      // ログアウト処理をして遷移させる
      Auth.signOut({ global: true })
        .then(() => navigate(Routings.buyerDeleteAccountComplete.location))
        .catch((err) => console.error(err));
    }
  });

  const onClickCancel = () => navigate(Routings.buyerAccount.location);

  const onClickWithdrawal = async () => {
    dispatch(member.operations.deleteMember());
  };

  return (
    <WithdrawalWrapper>
      <WithdrawalTextContainer>
        <div>
          退会された場合、以下の機能が
          <SpBr />
          ご利用いただけなくなります。
        </div>
        <FunctionListGroup>
          <ListItem>売出し中物件の閲覧</ListItem>
          <ListItem>借入可能金額の算出、購入可能物件の閲覧</ListItem>
          <ListItem>KUSABIからの物件紹介メールの受信</ListItem>
        </FunctionListGroup>
        <div>
          退会をご希望の方は、
          <SpBr />
          退会ボタンを押してください。
        </div>
        <WarningTextWrapper>
          <InfoIcon color="backgroundLight" />
          <WarningText>※退会は取り消すことができませんのでご注意ください。</WarningText>
        </WarningTextWrapper>
      </WithdrawalTextContainer>

      <ButtonContainer>
        <ButtonWrapper>
          <CancelButton onClick={onClickCancel} disabled={deleteMemberStatus.status === FetchStatus.LOADING}>
            キャンセル
          </CancelButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <WithdrawalButton onClick={onClickWithdrawal} disabled={deleteMemberStatus.status === FetchStatus.LOADING}>
            退会する
          </WithdrawalButton>
        </ButtonWrapper>
      </ButtonContainer>
    </WithdrawalWrapper>
  );
};

export default Before;
