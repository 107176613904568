import React from 'react';
import BuyerDeleteTemplate from '@/components/organisms/Buyer/Account/Delete/BuyerDeleteTemplate';
import Before from '@/components/organisms/Buyer/Account/Delete/Confirm';
import BuyerHeader from '@/components/atoms/Header/BuyerHeader';
import BuyerFooter from '@/components/atoms/Footer/BuyerFooter';
import { Wrapper } from '@/components/layout/common';
import { Routings } from '@/common/routings';
import { useDirectAccess } from '@/common/hooks';
import AuthenticationCheck from '@/components/organisms/AuthenticationCheck';
import withInitializeApp from '@/utils/hoc/withInitializeApp';

const BuyerDeletePage = () => {
  const isDirectAccess = useDirectAccess(Routings.buyerTop.location);

  return isDirectAccess ? (
    <></>
  ) : (
    <AuthenticationCheck>
      <Wrapper>
        <BuyerHeader displayPattern="inquiry" />
        <BuyerDeleteTemplate>
          <Before />
        </BuyerDeleteTemplate>
        <BuyerFooter />
      </Wrapper>
    </AuthenticationCheck>
  );
};

export default withInitializeApp(BuyerDeletePage);
