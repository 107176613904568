import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { useDispatch } from 'react-redux';
import isAuthenticated from '@/utils/authentication';
import { Routings } from '@/common/routings';
import * as buyer from '@/state/modules/buyer';

type Props = {
  children: React.ReactNode;
};

const AuthenticationCheck = ({ children }: Props) => {
  const dispatch = useDispatch();
  const currentLocation = useLocation();
  const [isAuthenticatedUser, setIsAuthenticatedUser] = useState(false);

  useEffect(() => {
    const getAuth = async () => {
      const isAuth = await isAuthenticated();
      if (!isAuth) {
        // ログイン後に対象の画面を表示できるようにパスを保存しておく
        dispatch(
          buyer.operations.savePath({
            redirectDist: currentLocation.pathname + currentLocation.search,
          })
        );
        navigate(Routings.login.location);
        setIsAuthenticatedUser(false);
      } else {
        setIsAuthenticatedUser(true);
      }
    };
    getAuth();
  }, []);

  return isAuthenticatedUser ? <>{children}</> : <></>;
};

export default AuthenticationCheck;
